import React from 'react';
import BlogList from './pages/BlogList'
import Home from './pages/Home';
import Login from './pages/Login';
import Post from './pages/Post';
import CreatePost from './components/CreatePost';
import EditPost from './components/EditPost';
import PrivateRoute from './components/PrivateRoute';
import DeletePost from './components/DeletePost';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';

function App() {
    return (
        <AuthProvider>
            <Router>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/blog" element={<BlogList/>}/>
                    <Route path="/blog/:id" element={<Post/>}/>
                    <Route path="/blog/:id/comments" element={<Post/>}/>
                    <Route path="/blog/:id/comments/:commentId/" element={<Post/>}/>
                    <Route
                        path="/blog/create"
                        element={
                            <PrivateRoute>
                                <CreatePost/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/blog/:id/update"
                        element={
                            <PrivateRoute>
                                <EditPost/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/blog/:id/delete"
                        element={
                            <PrivateRoute>
                                <DeletePost/>
                            </PrivateRoute>
                        }
                    />
                    <Route path="/login" element={<Login/>}/>
                </Routes>
            </Router>
        </AuthProvider>
    )
}

export default App;
