import React from 'react';
import './styles/Home.css'
import { Link } from 'react-router-dom';

function Home() {
    return(
        <div className="Home">
            <div className="panel left">
                <h1>hi. i'm nick.</h1>
                <p>electronic artist, developer</p>
            </div>
            <div className="panel right">
                <Link to="/blog" className="link">blog</Link>
                <Link to="https://open.spotify.com/artist/5E5FWkYCYQLx9WObAAwfcK" className="link">music</Link>
                <Link to="https://github.com/mistrrrmedia" className="link">github</Link>
                <Link to="mailto:music.mistrrr@gmail.com" className="link">contact</Link>
            </div>
        </div>
    );
}

export default Home;
