import React, { useEffect, useContext, useState } from 'react';
import api from '../utilities/api';
import TextareaAutosize from 'react-textarea-autosize';
import { AuthContext } from '../context/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import './styles/CreateEditPost.css';

function EditPost() {
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [embed_url, setEmbed_url] = useState("");
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(null);
    const { authToken } = useContext(AuthContext);
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchPost = async () => {
            try {
                const response = await api.get(`/blog/${id}`);
                setTitle(response.data.title);
                setContent(response.data.content);
                setEmbed_url(response.data.embed_url);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching post: ', error);
                setError('Failed to load post.');
                setLoading(false);
            }
        }
        fetchPost();
    }, [id]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await api.put(
                `/blog/${id}`,
                { title, content, embed_url: embed_url },
                {
                    headers: {
                        Authorization: authToken,
                    },
                }
            );
            navigate(`/blog/${id}`);
        } catch (error) {
            console.error('Error editing post: ', error);
            setError('Failed to edit post.');
        }
    };

    if(loading) {
        return (
            <div className="CreateEditPost">
                <p>Loading post...</p>
            </div>
        );
    }

    if(error) {
        return (
            <div className="Post">
                <div>
                    <h1>:( oh gosh.</h1>
                    <p>i don't know how this happened.</p>
                    <p className="error">Error: {error}</p>
                </div>
            </div>
        );
    }

    return (
        <div className="CreateEditPost">
            <div className="create-edit-container">
                <header>
                    <h1>update post</h1>
                    {error ? <p className="error">{error}</p> : <p><b><i>seriously?</i></b> you couldn't have put more effort into your senseless thought?</p>}
                </header>

                <hr/>

                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        placeholder="did you not catch enough eyes?"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        className="title-field"
                        required
                    />

                    <input
                        type="text"
                        placeholder="https://your-taste-is-trash.com/"
                        value={embed_url}
                        onChange={(e) => setEmbed_url(e.target.value)}
                        className="embed_url-field"
                    />

                    <TextareaAutosize
                        type="text"
                        placeholder="you should have left it as is. no one was going to read it anyway."
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                        className="content-field"
                        required
                    />

                    <button type="submit" className="submit">update</button>
                </form>
            </div>
        </div>
    )
}

export default EditPost;
