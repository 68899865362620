import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import api from '../utilities/api';
import './styles/BlogList.css'

function BlogList() {
    const [posts, setPosts] = useState([]);
    const [visiblePosts, setVisiblePosts] = useState(4);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const response = await api.get('/blog');
                setPosts(response.data.reverse());
            } catch (error) {
                console.error('Error fetching posts: ', error);
                setError('Failed to load posts.');
            }
        };
        fetchPosts();
    }, []);

    const loadMore = () => {
        setVisiblePosts((prevVisiblePosts) => prevVisiblePosts + 2);
    };

    if(error) {
        return (
            <div className="BlogList">
                <div>
                    <h1>:( oh gosh.</h1>
                    <p>i don't know how this happened.</p>
                    <p className="error">Error: {error}</p>
                </div>
            </div>
        );
    }

    return (
        <div className="BlogList">
            <div className="list-container">
                <header>
                    <h1>thoughts, jots & maybe bops</h1>
                    <p>this is my place to share little dootlets from my brain and recent jams that tickle my ears.</p>
                </header>

                <hr/>

                {sessionStorage.getItem("authToken") && <Link to={`/blog/create`} className="new-post">new post</Link>}

                <div>
                    {posts.slice(0, visiblePosts).map((post) => (
                        <div key={post.id} className="preview-container">
                            <h2>
                                <Link to={`/blog/${post.id}`} className="post-title-link">{post.title}</Link>
                            </h2>
                            <p>
                                {post.content.substring(0, 100)}
                                <Link to={`/blog/${post.id}`} className = "ellipsis">...</Link>
                            </p>
                        </div>
                    ))}
                </div>

                {visiblePosts < posts.length && (
                    <>
                        <br/>
                        <button onClick={loadMore}>load more</button>
                    </>
                )}
            </div>
        </div>
    );
}

export default BlogList;
