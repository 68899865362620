import React, { useEffect, useState, useContext } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { useParams } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import api from '../utilities/api';
import './styles/Comments.css';

function Comments() {
    const { id } = useParams();
    const { authToken } = useContext(AuthContext);
    const [comments, setComments] = useState(null);
    const [visibleComments, setVisibleComments] = useState(5);
    const [comment, setComment] = useState("");
    const [name, setName] = useState(localStorage.getItem('name'));
    const [loading, setLoading] = useState(true);
    const [loadingError, setLoadingError] = useState(null)
    const [commentError, setCommentError] = useState(null)

    const fetchComments = async () => {
        try {
            const response = await api.get(`/blog/${id}/comments`);
            setComments(response.data.reverse());
            setLoading(false);
        } catch(error) {
            console.error('Error loading comments: ', error);
            setLoadingError('Failed to load comments.');
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchComments();
    });

    const loadMore = () => {
        setVisibleComments((prevVisibleComments) => prevVisibleComments + 2);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const submissionName = (name !== "" ? name : "anonymous");
        try {
            await api.post(
                `/blog/${id}/comments`,
                { name: submissionName, comment },
            );
            setVisibleComments((prevVisibleComments) => prevVisibleComments + 1);
            setComment("");
            fetchComments();
        } catch (error) {
            console.error('Error leaving comment: ', error);
            setCommentError('Failed to leave comment.');
        }
    }

    const saveName = (name) => {
        setName(name);
        localStorage.setItem("name", name);
    }

    const deleteComment = async (commentId) => {
        try {
            await api.delete(
                `/blog/${id}/comments/${commentId}`,
                {
                    headers: {
                        Authorization: authToken
                    }
                }
            );
            fetchComments();
        } catch(error) {
            console.error(`Failed to delete comment ID: ${id}`, error);
        }
    }

    if(loading) {
        return (
            <div className="Comments">
                <p>Loading comments...</p>
            </div>
        );
    }

    // do not show error ui if 404, prompt the user to add a comment instead.
    // probs a bad idea to allow commenting too if retrieval fails.
    if(loadingError) {
        return (
            <div className="Comments">
                <div>
                    <h1>:( aw man...</h1>
                    <p>no socializing for today.</p>
                    <p className="error">Error: {loadingError}</p>
                </div>
            </div>
        );
    }

    return (
        <div className="Comments">
            <div>
                <h3>share your thoughts</h3>
                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        placeholder="who are you?"
                        value={name}
                        onChange={(e) => saveName(e.target.value)}
                        className="name-field"
                        maxLength={24}
                        required
                    />
                    <TextareaAutosize
                        type="text"
                        placeholder="what goes on in your mind?"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        className="comment-field"
                        required
                    />
                    <div className="submit-container">
                        {commentError ? <p>commentError</p> : <p>no hate speech, please.</p>}
                        <button type="submit">comment</button>
                    </div>
                </form>
            </div>
            {}
            <hr/>

            {comments.length === 0 &&
                <div>
                    <h4><i>*tumbling tumbleweed*</i></h4>
                    <h5>be the first share a dootlet.</h5>
                </div>
            }

            {comments.length > 0 &&
                <div>
                    {/* singular vs plural diff */}
                    {comments.length === 1 && <p className="counter" >1 comment</p>}
                    {comments.length > 1 && <p className="counter">{comments.length} comments</p>}

                    {comments.slice(0, visibleComments).map((comment) => (
                        <div key={comment.id} className="comment-container">
                            <div className="name-container">
                                <h4>{comment.name}</h4>
                                {sessionStorage.getItem("authToken") && <h4 className="delete" onClick={() => deleteComment(comment.id)}>delete</h4>}
                            </div>

                            {/* reformat new lines in plain text with <br/> tags in html */}
                            <p>{comment.comment.split('\n').map((line, index) => (
                                <span key={index}>
                                    {line}
                                    <br/>
                                </span>
                            ))}</p>

                            <p className="date">{comment.created_at}</p>
                        </div>
                    ))}

                    {visibleComments < comments.length && (
                        <>
                            <br/>
                            <button onClick={loadMore}>load more</button>
                        </>
                    )}
                </div>
            }
        </div>
    );
}

export default Comments;
