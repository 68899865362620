import React, { useState, useContext } from 'react';
import api from '../utilities/api';
import TextareaAutosize from 'react-textarea-autosize';
import { AuthContext } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import './styles/CreateEditPost.css';

function CreatePost() {
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [embed_url, setEmbed_url] = useState("");
    const [error, setError] = useState(null);
    const { authToken } = useContext(AuthContext);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await api.post(
                `/blog`,
                { title, content, embed_url: embed_url },
                {
                    headers: {
                        Authorization: authToken
                    }
                }
            );
            navigate(`/blog`);
        } catch (error) {
            console.error('Error creating post: ', error);
            setError('Failed to create post.');
        }
    };

    return (
        <div className="CreateEditPost">
            <div className="create-edit-container">
                <header>
                    <h1>create a post</h1>
                    {error ? <p className="error">{error}</p> : <p>let's cause some <b><i>issues</i></b>.</p>}
                </header>

                <hr/>

                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        placeholder="what are you going to ramble about?"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        className="title-field"
                        required
                    />

                    <input
                        type="text"
                        placeholder="https://what-fancied-your-ears-today.com/?"
                        value={embed_url}
                        onChange={(e) => setEmbed_url(e.target.value)}
                        className="embed_url-field"
                    />

                    <TextareaAutosize
                        type="text"
                        placeholder="go ahead, ramble. never stop talking."
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                        className="content-field"
                        required
                    />

                    <button type="submit" className="submit">create</button>
                </form>
            </div>
        </div>
    )
}

export default CreatePost;
