import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { format } from 'date-fns';
import api from '../utilities/api';
import Comments from '../components/Comments';
import './styles/Post.css';

function Post() {
    const { id } = useParams();
    const [post, setPost] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPost = async () => {
            try {
                const postResponse = await api.get(`/blog/${id}`);
                setPost(postResponse.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching post: ', error);
                setError('Failed to load post.');
                setLoading(false);
            }
        }
        fetchPost();
    }, [id]);

    if(loading) {
        return (
            <div className="Post">
                <p>Loading post...</p>
            </div>
        );
    }

    if(error) {
        return (
            <div className="Post">
                <div>
                    <h1>:( oh gosh.</h1>
                    <p>i don't know how this happened.</p>
                    <p className="error">Error: {error}</p>
                </div>
            </div>
        );
    }

    // reformat new lines in plain text with <br/> tags in html
    const formattedContent = post.content.split('\n').map((line, index) => (
        <span key={index}>
            {line}
            <br/>
        </span>
    ));

    return (
        <div className="Post">
            <div className="post-container">
                <header>
                    <h1>{post.title}</h1>
                </header>

                <hr/>

                {post.embed_url && <iframe src={post.embed_url} title="Embedded Content" className="embed"></iframe> }
                <p>{formattedContent}</p>
                <div className="date">
                    <p>written: {format(new Date(post.created_at), "MMMM dd, yyyy, h:mm:ss a").toLowerCase()}</p>
                    {post.updated_at && <p>last updated: {format(new Date(post.updated_at), "MMMM dd, yyyy, h:mm:ss a").toLowerCase()}</p>}
                </div>

                <br/>

                <p>
                    {/* <button className="toggle-comments">see comments</button> */}
                    {sessionStorage.getItem("authToken") && <Link to={`/blog/${id}/update`} className="edit">edit</Link>}
                    {sessionStorage.getItem("authToken") && <Link to={`/blog/${id}/delete`} className="delete">delete</Link>}
                </p>

                <Comments/>
            </div>
        </div>
    );
}

export default Post;
