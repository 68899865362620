import React, { useContext, useState } from 'react';
import api from '../utilities/api';
import { AuthContext } from '../context/AuthContext';
import { Link, useNavigate, useParams } from 'react-router-dom';
import './styles/DeletePost.css';

function DeletePost() {
    const { id } = useParams();
    const [error, setError] = useState(null);
    const { authToken } = useContext(AuthContext);
    const navigate = useNavigate();

    const requestDelete = async () => {
        try {
            await api.delete(
                `/blog/${id}`,
                {
                    headers: {
                        Authorization: authToken
                    }
                }
            );
            navigate(`/blog`);
        } catch(error) {
            console.error(`Failed to delete post ID: ${id}`, error);
            setError('Failed to delete post.');
        }
    }

    return (
        <div className="DeletePost">
            <div className="delete-post-container">
                <header>
                    <h1>delete post</h1>
                    <p>are you <i><b>sure</b></i>?</p>
                    {error && <p className="error">{error}</p>}
                </header>

                <div>
                    <Link to={`/blog/${id}`} className="cancel">cancel</Link>
                    <button onClick={requestDelete} className="confirmDelete">delete</button>
                </div>
            </div>
        </div>
    )
}

export default DeletePost;
