import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

function PrivateRoute({ children }) {
    const { isAuthenticated } = useContext(AuthContext);
    if(isAuthenticated){
        return children;
    }
    else {
        return <Navigate to="/login"/>;
    }
}

export default PrivateRoute;