import React, { useState, useContext } from 'react';
import api from '../utilities/api';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import './styles/Login.css';

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const { login } = useContext(AuthContext);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await api.post('/api/login', { username, password });
            login(response.data.token);
            navigate('/blog');
        } catch(error) {
            setError('you are stupid');
        }
    };

    return (
        <div className="login">
            <div className="panel left">
                <h1>admin login</h1>
                <p>prove yourself.</p>
                {error && <p className="error"><b><i>{error}</i></b> 🫶</p>}
            </div>

            <form onSubmit={handleSubmit} className="panel right">
                <input
                    type="text"
                    placeholder="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    className="login-field"
                    required
                />
                <input
                    type="password"
                    placeholder="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="login-field"
                    required
                />
                <button type="submit">login</button>
            </form>
        </div>
    );
}

export default Login;
